/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import {toast} from 'react-toastify'

export async function handleErrorAsToast(error: any) {
    let errorMessage = 'An unexpected error occurred'

    if (error instanceof Response) {
        // The error is a Response object, parse its body as JSON
        const errorData = await error.json()
        errorMessage = errorData.error
    } else if (error.response) {
        // The error is an Axios error
        errorMessage = error.response.data.error
    } else if (error.request) {
        errorMessage = 'No response received from server. Please try again later.'
    } else {
        // Something else happened that triggered an Error
        errorMessage = error.message
    }

    toast.error(errorMessage)
}

export async function handleSuccessAsToast(message: string) {
    toast.success(message)
}
