/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */
import React from 'react'
import {ChevronRightIcon, InformationCircleIcon} from '@heroicons/react/24/outline'
import {Breadcrumb, PAGES} from '@/constants/pages'
import {NavLink} from 'react-router-dom'

interface PageTitleProps {
    title?: string
    className?: string
    page: {
        displayName: string
        path: string
        breadcrumbs?: Breadcrumb[]
        description?: string
        [key: string]: any
    }
}

function PageTitle({title, page, className}: PageTitleProps) {
    return (
        <div className={className}>
            <nav className='flex' aria-label='Breadcrumb'>
                <ul className='my-0 inline-flex list-none items-center space-x-1 pl-0 md:space-x-3'>
                    {page.breadcrumbs &&
                        page.breadcrumbs.map((crumb, index, arr) => (
                            <li key={index} aria-current='page' className='ml-0 flex items-center pl-0'>
                                {!crumb.clickable ? (
                                    <span className='font-normal text-gray-500'>{crumb.label}</span>
                                ) : (
                                    <NavLink
                                        className='font-normal text-gray-500 no-underline hover:text-gray-800'
                                        to={crumb.path ?? PAGES.path}
                                    >
                                        {crumb.label}
                                    </NavLink>
                                )}
                                {index !== arr.length - 1 && (
                                    <ChevronRightIcon
                                        className='ml-2 h-4 w-4 flex-shrink-0 text-icon'
                                        aria-hidden='true'
                                    />
                                )}
                            </li>
                        ))}
                </ul>
            </nav>
            <div className='flex'>
                <h1 className='text-3xl'>{title ?? page.displayName}</h1>

                {page.description && (
                    <InformationCircleIcon
                        className='ml-2 h-5 w-5 flex-shrink-0 text-icon'
                        aria-hidden='true'
                        title={page.description}
                    />
                )}
            </div>
        </div>
    )
}

export default PageTitle
