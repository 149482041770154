/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import {Modal} from '@/components/Modal/Modal'
import ModalHeader from 'rsuite/esm/Modal/ModalHeader'
import ModalBody from 'rsuite/esm/Modal/ModalBody'
import ModalFooter from 'rsuite/esm/Modal/ModalFooter'
import React, {ReactNode} from 'react'

export interface DynamicModalProps {
    isOpen: boolean
    toggle: () => void
    heading: string
    body: ReactNode
    footer: ReactNode
    closeOnClickOutside?: boolean
    size?: 'sm' | 'md' | 'lg' | 'xl' | 'fit'
    disableInteraction?: boolean
}

export function DynamicModal({
    isOpen,
    toggle,
    heading,
    body,
    footer,
    closeOnClickOutside = true,
    size = 'sm',
    disableInteraction = false,
}: DynamicModalProps) {
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            closeOnClickOutside={disableInteraction ? false : closeOnClickOutside}
            size={size}
        >
            <ModalHeader onClose={disableInteraction ? undefined : toggle}>
                <strong>{heading}</strong>
            </ModalHeader>
            <hr />
            <ModalBody>{body}</ModalBody>
            <ModalFooter className='flex justify-between'>{footer}</ModalFooter>
        </Modal>
    )
}
