/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2020] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */
import React, {Suspense} from 'react'
import {ArrowRightOnRectangleIcon, UsersIcon} from '@heroicons/react/24/outline'
import {NavMenuItem} from '@/types'
import {Navigate} from 'react-router-dom'
import {Cog6ToothIcon, ComputerDesktopIcon, RocketLaunchIcon} from '@heroicons/react/20/solid'
import {NEW_CLIENT_URL_PARAM} from '@/pages/clients/ClientDetails'

const Clients = React.lazy(() => import('@/pages/clients/Clients'))
const ClientDetails = React.lazy(() => import('@/pages/clients/ClientDetails'))
const Login = React.lazy(() => import('../pages/auth/Login'))
const LogOut = React.lazy(() => import('../pages/auth/Logout'))
const Configuration = React.lazy(() => import('@/pages/configuration/config/Config'))
const ApiKeys = React.lazy(() => import('@/pages/configuration/api-keys/ApiKeys'))
const Users = React.lazy(() => import('@/pages/users/Users'))

function prependPath(object: any, path?: string) {
    Object.keys(object).forEach((key) => {
        if (typeof object[key] === 'object' && 'path' in object[key]) {
            let prependedPath = (path ?? '') + object[key].path + '/'
            object[key].path = prependedPath

            if (typeof object[key].element === 'function') {
                const Component = object[key].element
                object[key].element = <Component />
            } else if (object[key].element?.$$typeof) {
                const Component = object[key].element

                if (object.requiredRoles !== undefined) {
                    if (object[key].requiredRoles === undefined) {
                        object[key].requiredRoles = object.requiredRoles
                    }
                }

                // Wrap lazy imports in suspense
                if (Symbol.keyFor(object[key].element.$$typeof) === 'react.lazy') {
                    object[key].element = (
                        <Suspense>
                            <Component />
                        </Suspense>
                    )
                }
            }

            prependPath(object[key], prependedPath)
        }
    })
}

export interface Breadcrumb {
    label: string
    clickable?: boolean
    path?: string
}

function generateBreadcrumbs(object: any, path: any[]) {
    Object.keys(object).forEach((key) => {
        if (typeof object === 'object' && object.displayName) {
            const crumbs = [
                ...path.map((it) => ({...it, clickable: true})),
                {label: object.displayName, clickable: false, path: object.path},
            ]
            object.breadcrumbs = crumbs

            generateBreadcrumbs(object[key], crumbs)
        }
    })
}

function createNavItems(pages: any): NavMenuItem[] {
    let result = []
    for (let key in pages) {
        if (typeof pages[key] === 'object' && pages[key] !== null && pages[key].isNavItem === true) {
            let item: NavMenuItem = {
                displayName: pages[key].displayName,
                path: pages[key].path,
                icon: pages[key].icon,
                isNavHeader: pages[key].element === undefined,
                isNavItem: pages[key].isNavItem && pages[key].element !== undefined,
                children: createNavItems(pages[key]),
                requiredRoles: pages[key].requiredRoles,
            }
            if (item.children!.length === 0) {
                item.children = undefined
            }
            result.push(item)
        }
    }
    return result
}

export const LOGIN_PAGES = {
    path: '/',
    LOGIN: {
        displayName: 'Login',
        path: 'authenticate',
        element: Login,
    },
    FORGOT_PASSWORD: {
        displayName: 'Forgot Password',
        path: 'forgot-password',
        element: <h1>Forgot Password</h1>,
    },
    CHANGE_PASSWORD: {
        displayName: 'Change Password',
        path: 'change-password',
        element: <h1>Change Password</h1>,
    },
    USER_SETUP: {
        displayName: 'User Setup',
        path: 'user-setup',
        element: <h1>User Setup</h1>,
    },

    init: function () {
        prependPath(this, this.path)
        return this
    },
}.init()

export const APP_PAGES = {
    path: '/',
    displayName: 'Squid Server',
    element: <Navigate to='/clients' />,

    ERROR_PAGE: {
        displayName: 'Error',
        path: 'error',
        element: <h1>Hello Error</h1>,
    },

    CLIENTS: {
        displayName: 'Clients',
        path: 'clients',
        isNavItem: true,
        icon: ComputerDesktopIcon,
        element: Clients,

        DETAILS: {
            displayName: 'Details',
            path: ':id',
            element: ClientDetails,
            pathTo: function (id: number | string) {
                return this.path.replace(':id', id.toString())
            },
        },
        CREATE: {
            displayName: 'Create',
            path: NEW_CLIENT_URL_PARAM,
            element: ClientDetails,
            pathTo: function () {
                return this.path
            },
        },
    },

    USERS: {
        displayName: 'Users',
        path: 'users',
        icon: UsersIcon,
        isNavItem: true,
        element: Users,
    },

    CONFIGURATION: {
        displayName: 'Configuration',
        path: 'configuration',
        isNavItem: true,
        icon: Cog6ToothIcon,

        CONFIG: {
            displayName: 'Config',
            path: 'config',
            icon: Cog6ToothIcon,
            isNavItem: true,
            element: Configuration,
        },
        API_KEYS: {
            displayName: 'API keys',
            path: 'api-keys',
            icon: RocketLaunchIcon,
            isNavItem: true,
            element: ApiKeys,
        },
    },
    ACCOUNTS: {
        displayName: 'User Management',
        icon: UsersIcon,
        path: 'accounts',

        USER_SETTINGS: {
            displayName: 'User Settings',
            path: 'user-settings',
            element: <h1>Hello User Settings</h1>,
        },
    },

    LOGOUT: {
        displayName: 'Logout',
        path: 'logout',
        icon: ArrowRightOnRectangleIcon,
        element: LogOut,
    },

    init: function () {
        prependPath(this, this.path)
        generateBreadcrumbs(this, [])
        return this
    },
}.init()

const PROFILE_DROPDOWN = {
    USER_SETTINGS: {...APP_PAGES.ACCOUNTS.USER_SETTINGS, isNavItem: true},
    LOGOUT: {...APP_PAGES.LOGOUT, isNavItem: true},
}

export const NAVITEMS = createNavItems(APP_PAGES)
export const PROFILE_ITEMS = createNavItems(PROFILE_DROPDOWN)
export const PAGES = APP_PAGES
