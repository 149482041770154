/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */
import React from 'react'
import ReactDOM from 'react-dom/client'

import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import routes from './routes/routes'
import {AuthProvider} from '@/auth/authContext'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import './index.less'
import {API} from '@/constants/api'
import '@motiondesign/npm-common-utils/extensions'

declare global {
    var gRecaptchaSiteKey: string
}

// Vite does not set this variable unlike webpack.
// Needed for react-joyride, can be removed is this lib is not used.
window.global ||= window

const router = createBrowserRouter(routes)

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <AuthProvider getProfileInfo={() => API.APP.USER.PROFILE.get()}>
            <RouterProvider router={router} />
        </AuthProvider>
        <ToastContainer />
    </React.StrictMode>,
)
