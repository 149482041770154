/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import {Dialog, Transition} from '@headlessui/react'
import React, {Fragment, PropsWithChildren} from 'react'
import classNames from 'classnames'

export interface ModalProps extends PropsWithChildren {
    isOpen: boolean
    toggle: () => void
    closeOnClickOutside?: boolean
    size?: 'sm' | 'md' | 'lg' | 'xl' | 'fit'
}

const widthClasses = {
    sm: 'w-full max-w-md',
    md: 'w-full max-w-[33vw]',
    lg: 'w-full max-w-[50vw]',
    xl: 'w-full max-w-[66vw]',
    fit: '',
}

export function Modal({isOpen, toggle, children, closeOnClickOutside = true, size = 'sm'}: ModalProps) {
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as='div' className='relative z-[6]' onClose={() => closeOnClickOutside && toggle()}>
                <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <div className='fixed inset-0 bg-black bg-opacity-25' />
                </Transition.Child>

                <div className='fixed inset-0 overflow-y-auto'>
                    <div className='flex min-h-full items-center justify-center text-center'>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 scale-95'
                            enterTo='opacity-100 scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 scale-100'
                            leaveTo='opacity-0 scale-95'
                        >
                            <Dialog.Panel
                                className={classNames(
                                    widthClasses[size],
                                    'rounded-xl bg-white p-6 text-left align-middle shadow-xl transition-all',
                                )}
                            >
                                {children}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}
