/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2022] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import {useCallback, useState} from 'react'

/**
 * Returns an object containing the elements from the items array, indexed by the key returned from getKey
 * function applied to each element.
 * If any two elements have the same key returned by getKey, the last one gets added to the object.
 * Equivalent to Kotlin's associateBy function.
 * Discards any items where getKey returns undefined.
 * @param items Source array of items.
 * @param getKey Function returning the key to group by.
 */
export function associateBy<T>(items: T[], getKey: (item: T) => string | number | undefined): {[key: string]: T} {
    return items.reduce((acc: {[key: string]: T}, item) => {
        const key = getKey(item)
        if (key !== undefined) {
            acc[key.toString()] = item
        }
        return acc
    }, {})
}

/**
 * Groups elements of the original array by the key returned by the given getKey function applied to each
 * element and returns an object where each group key is associated with an array of corresponding elements.
 * Discards any items where getKey returns undefined.
 * @param items Source array of items.
 * @param getKey Function returning the key to group by.
 * @param accumulator Optional - The base accumulator value of the reduce function.
 */
export function groupBy<T>(
    items: T[],
    getKey: (item: T) => string | number | undefined,
    accumulator: Record<string, T[]> = {},
): Record<string, T[]> {
    return items.reduce((acc: {[key: string]: T[]}, item) => {
        const key = getKey(item)
        if (key !== undefined) {
            if (acc[key] === undefined) {
                acc[key] = [item]
            } else {
                acc[key].push(item)
            }
        }
        return acc
    }, accumulator)
}

/**
 * Partitions an array into two arrays depending on if each element passes or fails the given predicate.
 * @param array Array to partition into two arrays.
 * @param predicate Function called with each element as its argument.
 * @returns Tuple of two T[], with the first one containing all elements that passed the predicate and the second
 * being all elements that failed the predicate.
 */
export function partition<T>(array: Array<T>, predicate: (element: T) => boolean) {
    return array.reduce(
        ([pass, fail]: T[][], element: T) => {
            return predicate(element) ? [[...pass, element], fail] : [pass, [...fail, element]]
        },
        [[], []],
    )
}

/**
 * If the default value is not undefined, return it inside a resolved promise.
 * Otherwise, execute the promiseCreator function.
 * @param promiseCreator A function that returns a promise.
 * @param defaultValue A default value or undefined.
 */
export function defaultValueOrPromise<T>(promiseCreator: () => Promise<T>, defaultValue?: T): Promise<T> {
    if (defaultValue === undefined) {
        return promiseCreator()
    } else {
        return Promise.resolve(defaultValue)
    }
}

/**
 * Sorts the objects in items by the order their IDs appear in the idsOrder array.
 * @param items An array of objects which have a numeric id property.
 * @param idsOrder The order to sort the items into.
 */
export function sortObjectArrayById<T extends {id: number}>(items: T[], idsOrder: number[]): T[] {
    const byId = associateBy(items, (it) => it.id)
    return idsOrder.map((id) => byId[id])
}

type ClassStateAction<S> = Partial<S> | ((prevState: S) => Partial<S>)

/**
 * A wrapper around the setState hook that preserves class component partial update setState behaviour,
 * for the purpose of making it easier to refactor class components into functional components.
 * @param initialState
 */
export function useClassState<S>(initialState: S | (() => S)): [S, (stateUpdate: ClassStateAction<S>) => void] {
    const [state, __setState] = useState<S>(initialState)

    const setState = useCallback(
        (stateUpdate: ClassStateAction<S>) => {
            __setState((prevState) => ({
                ...prevState,
                ...(typeof stateUpdate === 'function' ? stateUpdate(prevState) : stateUpdate),
            }))
        },
        [__setState],
    )

    return [state, setState]
}

/**
 * Function to see whether a string contains a substring, ignoring case.
 * @param string the string to see whether it contains a substring.
 * @param substring the substring to see if a string includes.
 * @return whether the string includes a substring, ignoring case.
 */
export function includesIgnoreCase(string: string, substring: string) {
    return string.toLowerCase().includes(substring.toLowerCase())
}

export function copyTextToClipboard(text: string) {
    return navigator.clipboard.writeText(text)
}

/**
 * Splice function, but it returns a copy of the modified original array instead.
 */
export function copyAndSplice<T>(array: T[], index: number, deleteCount: number, ...items: T[]): T[] {
    const copy = [...array]
    copy.splice(index, deleteCount, ...items)
    return copy
}

export function arrSet<T>(array: T[], index: number, item: T): T[] {
    const copy = [...array]
    copy[index] = item
    return copy
}

export function sortBy<T>(key: keyof T, array: T[], direction: 'asc' | 'desc' = 'asc'): T[] {
    return array.sort((a, b) => {
        const valueA = a[key]
        const valueB = b[key]

        if (valueA < valueB) {
            return direction === 'asc' ? -1 : 1
        }
        if (valueA > valueB) {
            return direction === 'asc' ? 1 : -1
        }
        return 0
    })
}

export function getSortBy<T>(key: keyof T, direction: 'asc' | 'desc' = 'asc'): (array: T[]) => T[] {
    return (array: T[]) => sortBy(key, array, direction)
}

export function getServerOrigin() {
    return window.location.hostname === 'localhost' ? 'http://localhost:8080' : window.location.origin
}
