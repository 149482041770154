/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import React from 'react'
import classNames from 'classnames'
const ButtonRole = {
    'primary': 'text-white bg-sky-700 hover:bg-sky-600  focus-visible:outline-sky-600',
    'secondary': 'text-white bg-gray-800 hover:bg-gray-700  focus-visible:outline-gray-700',
    'success': 'text-white bg-green-700 hover:bg-green-600 focus-visible:outline-green-600',
    'danger': 'text-white bg-red-700 hover:bg-red-600 focus-visible:outline-red-600',
    'warning': 'text-white bg-yellow-400 hover:bg-yellow-300 focus-visible:outline-yellow-300',
    'outline-primary':
        'text-sky-700 hover:text-white border border-sky-700 hover:bg-sky-600  text-center focus-visible:outline-sky-600',
    'outline-secondary':
        'text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-700  text-center focus-visible:outline-gray-700',
    'outline-success':
        'text-green-700 hover:text-white border border-green-700 hover:bg-green-600  text-center focus-visible:outline-green-600',
    'outline-danger':
        'text-red-700 hover:text-white border border-red-700 hover:bg-red-600  text-center focus-visible:outline-red-600',
    'outline-warning':
        'text-yellow-400 hover:text-white border border-yellow-400 hover:bg-yellow-300  text-center focus-visible:outline-yellow-300',
}

export const ButtonSize = {
    small: 'py-1 px-2.5',
    medium: 'py-1.5 px-3.5',
    large: 'py-2.5 px-4',
}

export type ButtonProps = Omit<React.HTMLProps<HTMLButtonElement>, 'size'> & {
    size?: keyof typeof ButtonSize
    color?: keyof typeof ButtonRole
    iconRight?: JSX.Element
    iconLeft?: JSX.Element
}

const Button = ({
    children,
    iconLeft,
    iconRight,
    color = 'primary',
    size = 'medium',
    type = 'button',
    className,
    ...rest
}: ButtonProps) => (
    <button
        {...rest}
        // @ts-ignore
        type={type}
        className={classNames(
            className,
            ButtonRole[color],
            ButtonSize[size],
            'whitespace-nowrap rounded-md font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ',
            'box-border focus:outline-none disabled:pointer-events-none disabled:opacity-60',
            'inline-flex items-center gap-x-1.5',
        )}
    >
        {iconLeft &&
            React.cloneElement(iconLeft, {
                'aria-hidden': true,
                ...iconLeft.props.className,
                'className': classNames('-ml-0.5 h-5 w-5', iconLeft.props.className),
            })}
        {children}
        {iconRight &&
            React.cloneElement(iconRight, {
                'aria-hidden': true,
                ...iconRight.props,
                'className': classNames('-mr-0.5 h-5 w-5', iconRight.props.className),
            })}
    </button>
)

export default Button
