/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import React, {FormEvent, ReactElement, ReactNode, useCallback, useEffect, useId, useRef, useState} from 'react'
import Button from '@/components/Button/Button'
import {Modal, ModalProps} from '@/components/Modal/Modal'
import {MDCheckboxInput, MDInput, ToggleSwitch} from '@/components/Forms'
import {
    addDays,
    addMonths,
    addQuarters,
    addWeeks,
    endOfDay,
    endOfISOWeek,
    endOfMonth,
    endOfQuarter,
    endOfWeek,
    formatISO,
    parseISO,
    setHours,
    startOfDay,
    startOfISOWeek,
    startOfMonth,
    startOfQuarter,
} from 'date-fns'
import {RangeType} from 'rsuite/esm/DateRangePicker/types'
import DateRangePicker from 'rsuite/DateRangePicker'
import DatePicker from 'rsuite/DatePicker'
import {PlusIcon, XMarkIcon} from '@heroicons/react/24/outline'
import {copyAndSplice} from '@/helpers/common'
import {CardBody, CardHeader} from '@/components/Card/Card'
import {MDSelect} from '@/components/Select/MDSelect'

const DateRangePresets: (RangeType & {appearance?: string})[] = [
    {
        label: 'Today',
        value: [startOfDay(new Date()), endOfDay(new Date())],
        placement: 'left',
        closeOverlay: false,
    },
    {
        label: 'Tomorrow',
        value: [startOfDay(addDays(new Date(), 1)), endOfDay(addDays(new Date(), 1))],
        placement: 'left',
        closeOverlay: false,
    },
    {
        label: 'This week',
        value: [startOfDay(startOfISOWeek(new Date())), endOfISOWeek(endOfWeek(new Date()))],
        placement: 'left',
        closeOverlay: false,
    },
    {
        label: 'Next week',
        value: [startOfDay(addWeeks(startOfISOWeek(new Date()), 1)), endOfISOWeek(addWeeks(endOfWeek(new Date()), 1))],
        placement: 'left',
        closeOverlay: false,
    },
    {
        label: 'This Month',
        value: [startOfDay(startOfMonth(new Date())), endOfDay(endOfMonth(new Date()))],
        placement: 'left',
        closeOverlay: false,
    },
    {
        label: 'Next Month',
        value: [startOfDay(addMonths(startOfMonth(new Date()), 1)), endOfDay(addMonths(endOfMonth(new Date()), 1))],
        placement: 'left',
        closeOverlay: false,
    },
    {
        label: 'This Quarter',
        value: [startOfDay(startOfQuarter(new Date())), endOfDay(endOfQuarter(new Date()))],
        placement: 'left',
        closeOverlay: false,
    },
    {
        label: 'Next Quarter',
        value: [
            startOfDay(addQuarters(startOfQuarter(new Date()), 1)),
            endOfDay(addQuarters(endOfQuarter(new Date()), 1)),
        ],
        placement: 'left',
        closeOverlay: false,
    },
]

// Base HTMLInputType has a catch-all string at the end, breaking the type discrimination.
export type InputType =
    | 'button'
    | 'checkbox'
    | 'toggle'
    | 'color'
    | 'time'
    | 'date'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'textarea'
    | 'url'
    | 'week'

export type FormField = InputFormField | CustomFormField | SelectFormField<any> | DateRangeField

interface BaseFormField {
    label: string
    name: string
    helpText?: string
    required?: boolean
    validator?: (formData: any) => string | undefined
    isMulti?: boolean
    value?: any | any[]
    defaultValue?: any | any[]
    readOnly?: boolean
    disabled?: boolean
}

interface InputFormField extends BaseFormField {
    type: InputType
    placeholder?: string
}

interface DateRangeField extends BaseFormField {
    type: 'date-range' | 'time-range' | 'datetime-range'
    startField: string
    endField: string
}

interface CustomFormField extends BaseFormField {
    type: 'custom'
    component: (value: any, onChange: (value: any) => void, readOnly: boolean, allData: any) => ReactElement
}

interface SelectFormField<T> extends BaseFormField {
    type: 'select'
    isMultiSelect?: boolean
    isClearable?: boolean
    selectOptions: T[]
    optionLabelField?: string
    optionValueField?: string
}

export interface DynamicFormProps {
    readOnly?: boolean
    fields: FormField[]
    onChange?: (formData: any) => void
    onValidChanged?: (isValid: boolean) => void
    onSave?: (formData: any) => void
    initialState?: any
    children?: (isValid: boolean, onSave?: (formData: any) => void) => ReactNode
    zIndex?: number
}

interface DynamicFormModalProps extends DynamicFormProps, Omit<ModalProps, 'children'> {
    title: string
    toggle: () => void
    isOpen: boolean
    onSave: (formData: any) => void
    zIndex?: number
}

export const TimeRangePresets: (RangeType & {appearance?: string})[] = [
    {
        label: '24hrs',
        value: [startOfDay(new Date()), endOfDay(new Date())],
        placement: 'left',
        closeOverlay: true,
    },
    {
        label: '9 to 5',
        value: [setHours(startOfDay(new Date()), 9), setHours(startOfDay(new Date()), 17)],
        placement: 'left',
        closeOverlay: true,
    },
]

interface ComponentForInputTypeProps {
    field: FormField
    readOnly: boolean
    formData: any
    onChange: (updates: any) => void
    id: string
}

const ComponentForInputType = ({field, readOnly, formData, onChange, id}: ComponentForInputTypeProps): ReactElement => {
    const isMulti = field.isMulti
    const label = isMulti ? undefined : field.label

    switch (field.type) {
        case 'custom':
            return field.component?.(
                formData[field.name],
                (value: any) => onChange({[field.name]: value}),
                readOnly ?? false,
                formData,
            )
        case 'select':
            return (
                <div title={field.helpText} className='relative mb-4 flex flex-col '>
                    {label && (
                        <label htmlFor={field.name} className='mb-1 text-slate-500'>
                            {field.label}
                        </label>
                    )}
                    <MDSelect
                        isMulti={field.isMultiSelect}
                        isClearable={field.isClearable}
                        name={field.name}
                        options={field.selectOptions}
                        getOptionLabel={(option) => option[field.optionLabelField ?? 'name'].toString()}
                        getOptionValue={(option) => option[field.optionValueField ?? 'id'].toString()}
                        value={formData[field.name]}
                        onChange={(newValue) => onChange({[field.name]: newValue})}
                        isDisabled={readOnly}
                    />
                </div>
            )
        case 'toggle':
            return (
                <div title={field.helpText} className='mb-4 flex flex-col'>
                    <ToggleSwitch
                        key={field.name}
                        name={field.name}
                        label={field.label}
                        id={`form${id}_${field.name}`}
                        checked={formData[field.name]?.toString() === 'true'}
                        onChange={(newValue) => onChange({[field.name]: newValue.toString()})}
                        title={field.helpText}
                        readOnly={readOnly}
                    />
                </div>
            )
        case 'checkbox':
            const checked = formData[field.name]?.toString() === 'true'
            return (
                <div title={field.helpText} className='mb-4'>
                    <MDCheckboxInput
                        label={field.label}
                        key={field.name}
                        name={field.name}
                        id={`form${id}_${field.name}`}
                        checked={checked}
                        onChange={(_, newValue) => {
                            onChange({[field.name]: newValue.toString()})
                        }}
                        title={field.helpText}
                        readOnly={readOnly}
                    />
                </div>
            )
        case 'time-range': // Fall through
        case 'datetime-range': // Fall through
        case 'date-range': {
            const formats = {
                'date-range': 'yyyy-MM-dd',
                'time-range': 'HH:mm:ss',
                'datetime-range': 'yyyy-MM-dd HH:mm:ss',
            }
            const presets = {
                'date-range': DateRangePresets,
                'datetime-range': DateRangePresets,
                'time-range': TimeRangePresets,
            }

            const data = (field.isMulti ? formData[field.name] : formData) ?? {}
            const value: [Date, Date] | null =
                data[field.startField] && data[field.endField]
                    ? [parseISO(data[field.startField]), parseISO(data[field.endField])]
                    : null

            return (
                <div title={field.helpText} className='flex flex-col'>
                    {!isMulti && (
                        <label htmlFor={`form${id}_${field.name}`} className='mb-1  text-slate-500'>
                            {field.label}
                        </label>
                    )}
                    <DateRangePicker
                        name={field.name}
                        id={`form${id}_${field.name}`}
                        format={formats[field.type]}
                        placeholder='Schedule Range'
                        readOnly={readOnly}
                        character=' - '
                        cleanable={!field.required}
                        isoWeek={true}
                        value={value}
                        ranges={presets[field.type]}
                        onChange={(range) => {
                            if (range) {
                                onChange({
                                    [field.startField]: formatISO(range[0]),
                                    [field.endField]: formatISO(range[1]),
                                })
                            } else {
                                onChange({
                                    [field.startField]: null,
                                    [field.endField]: null,
                                })
                            }
                        }}
                    />
                </div>
            )
        }
        case 'time':
        case 'date': {
            const formats = {
                date: 'yyyy-MM-dd',
                time: 'HH:mm:ss',
            }
            return (
                <div className='mb-4 flex flex-col'>
                    {label && (
                        <label htmlFor={`form${id}_${field.name}`} className='mb-1  text-slate-500'>
                            {field.label}
                        </label>
                    )}
                    <DatePicker
                        id={`form${id}_${field.name}`}
                        // style={{zIndex}}
                        format={formats[field.type]}
                        name={field.name}
                        readOnly={readOnly}
                        value={formData[field.name] ? parseISO(formData[field.name]) : new Date()}
                        onChange={(date) => date && onChange({[field.name]: formatISO(date)})}
                    />
                </div>
            )
        }
        default: {
            return (
                <MDInput
                    label={label}
                    type={field.type}
                    name={field.name}
                    id={`form${id}_${field.name}`}
                    placeholder=' '
                    required={field.required}
                    value={formData[field.name] ?? ''}
                    onChange={(newValue) => onChange({[field.name]: newValue})}
                    title={field.helpText}
                    readOnly={readOnly}
                    disabled={field.disabled}
                />
            )
        }
    }
}

function DynamicForm({fields, onSave, onChange, children, initialState, readOnly, ...rest}: DynamicFormProps) {
    const getInitialState = useCallback(() => {
        return (
            initialState ??
            Object.fromEntries(fields.map((field) => [field.name, field.defaultValue ?? (field.isMulti ? [] : '')]))
        )
    }, [initialState, fields])

    const [formData, setFormData] = useState(getInitialState)

    // Work-around to not call onChanged when formData is reset by initialState changing.
    // The ref is only updated by user input.
    const formDataChangedByUserInputRef = useRef<any>()
    useEffect(() => {
        formDataChangedByUserInputRef.current && onChange?.(formDataChangedByUserInputRef.current)
    }, [formDataChangedByUserInputRef.current])

    // Reset state if initialState changes
    useEffect(() => setFormData(getInitialState), [getInitialState])

    const onChangeHandler = (updates: any, multiIndex?: number, field?: string) => {
        if (multiIndex === undefined) {
            setFormData((prevState: any) => {
                const newData = {...prevState, ...updates}
                formDataChangedByUserInputRef.current = newData
                return newData
            })
        } else {
            const changes = Object.keys(updates).length > 1 ? updates : updates[field!]
            setFormData((prevState: any) => {
                const newData = {
                    ...prevState,
                    [field!]: copyAndSplice(prevState[field!], multiIndex, 1, changes),
                }
                formDataChangedByUserInputRef.current = newData
                return newData
            })
        }
    }

    const addItemToMulti = (field: FormField) => {
        setFormData((prevState: any) => {
            const newData = {
                ...prevState,
                [field.name]: [...prevState[field.name], field.defaultValue ?? null],
            }
            formDataChangedByUserInputRef.current = newData
            return newData
        })
    }

    const removeMultiItem = (field: FormField, index: number) => {
        setFormData((prevState: any) => {
            const newData = {...prevState, [field.name]: copyAndSplice(prevState[field.name], index, 1)}
            formDataChangedByUserInputRef.current = newData
            return newData
        })
    }

    // Used to avoid clashes between other modals with fields of the same name that may also be mounted.
    const id = useId()

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        onSave?.(formData)
    }

    const validationErrors: any = {}
    const isValid = fields.every((field) => {
        let valid = true
        if (field.required === true) {
            if (field.type === 'date-range' || field.type === 'time-range') {
                valid = valid && !!(formData[field.startField] && formData[field.endField])
            } else {
                valid = !!formData[field.name] && String(formData[field.name]).length > 0
            }
            !valid && console.warn('invalid field: ', field.name)
        }
        if (field.validator && formData[field.name] !== undefined) {
            const errorMessage = field.validator(formData)
            validationErrors[field.name] = errorMessage

            valid = valid && errorMessage === undefined
        }
        return valid
    })

    useEffect(() => {
        rest.onValidChanged?.(isValid)
    }, [isValid])

    return (
        <form autoComplete='off' onSubmit={onSubmit} className='grow'>
            {fields.map((field, fieldIndex) =>
                field.isMulti ? (
                    <div className='mb-4' key={`${field.name}-${fieldIndex}`}>
                        <div className='mb-2 flex items-baseline justify-between'>
                            <div className='mb-1 grow text-slate-500'>{field.label}</div>
                            <Button size='small' onClick={() => addItemToMulti(field)} iconLeft={<PlusIcon />}>
                                Add Item
                            </Button>
                        </div>
                        {formData[field.name]?.map((value: any, multiIndex: number) => (
                            <div className='mb-2 flex' key={`${fieldIndex}-${multiIndex}`}>
                                <div className='mr-2 grow'>
                                    <ComponentForInputType
                                        key={`${field.name}-${multiIndex}`}
                                        field={field}
                                        readOnly={!!readOnly || !!field.readOnly}
                                        formData={{...formData, [field.name]: formData[field.name][multiIndex]}}
                                        onChange={(changes: any) => onChangeHandler(changes, multiIndex, field.name)}
                                        id={`${id}-${multiIndex}`}
                                    />
                                </div>
                                <Button
                                    color='outline-danger'
                                    onClick={() => removeMultiItem(field, multiIndex)}
                                    iconLeft={<XMarkIcon />}
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div key={fieldIndex} className='mb-4'>
                        <ComponentForInputType
                            field={field}
                            readOnly={!!readOnly || !!field.readOnly}
                            formData={formData}
                            onChange={onChangeHandler}
                            id={id}
                        />
                    </div>
                ),
            )}
            {children?.(isValid, onSave?.bind(undefined, formData))}
        </form>
    )
}

function DynamicFormModal({
    title,
    fields,
    onSave,
    toggle,
    isOpen,
    onChange,
    initialState,
    zIndex = 22,
    ...rest
}: DynamicFormModalProps) {
    return (
        <Modal isOpen={isOpen} toggle={toggle} {...rest}>
            <CardHeader>{title && <strong>{title}</strong>}</CardHeader>
            <CardBody>
                <DynamicForm
                    fields={fields}
                    onSave={onSave}
                    onChange={onChange}
                    initialState={initialState}
                    zIndex={zIndex}
                >
                    {(isValid) => (
                        <div className='mt-6 flex justify-between'>
                            <Button type='button' color='outline-secondary' onClick={toggle}>
                                Cancel
                            </Button>
                            <Button disabled={!isValid} type='submit'>
                                Save
                            </Button>
                        </div>
                    )}
                </DynamicForm>
            </CardBody>
        </Modal>
    )
}

export {DynamicFormModal, DynamicForm}
