/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */
import React from 'react'
import {Disclosure} from '@headlessui/react'
import {NavMenuItem} from '@/types'
import {NavLink, useLocation} from 'react-router-dom'
import classNames from 'classnames'

export type NavigationProps = {items: NavMenuItem[]; className?: string}

const Navigation = ({items, className}: NavigationProps) => {
    const {pathname} = useLocation()

    const applyActiveState = (item: NavMenuItem): NavMenuItem => {
        return {
            ...item,
            current: pathname === item.path,
            children: item.children ? item.children.map(applyActiveState) : undefined,
        }
    }
    return (
        <nav className={classNames(className, 'flex-1 space-y-1 px-2')} aria-label='Sidebar'>
            {items.map(applyActiveState).map((item) => {
                return !item.children ? (
                    <div key={item.displayName}>
                        <NavLink
                            to={item.path}
                            className={classNames(
                                item.current ? 'bg-opacity-100 text-opacity-100' : 'bg-opacity-0 text-opacity-80',
                                'bg-inverse-200  hover:bg-opacity-50',
                                'text-primary  hover:text-opacity-100',
                                'group flex w-full items-center rounded-md py-2 pl-7 pr-2 font-medium',
                            )}
                        >
                            {item.icon && (
                                <item.icon className='mr-4 h-4 w-4 flex-shrink-0 text-icon' aria-hidden='true' />
                            )}
                            {item.displayName}
                        </NavLink>
                    </div>
                ) : (
                    <Disclosure
                        defaultOpen={true} //item.children.some((item) => item.current)
                        as='div'
                        key={item.displayName}
                        className='space-y-1'
                    >
                        {({open}) => (
                            <>
                                <Disclosure.Button
                                    className={classNames(
                                        item.current
                                            ? 'bg-opacity-100 text-opacity-100'
                                            : 'bg-opacity-0 text-opacity-80',
                                        'bg-inverse-200  hover:bg-opacity-50',
                                        'text-primary  hover:text-opacity-100',
                                        'group flex w-full items-center rounded-md py-2 pr-2 text-left font-medium',
                                    )}
                                >
                                    <svg
                                        className={classNames(
                                            open ? 'rotate-90 text-opacity-60' : 'text-opacity-30',
                                            'group-hover:text-opacity-60',
                                            'mr-2 h-5 w-5 flex-shrink-0 transform text-icon transition-colors duration-150 ease-in-out ',
                                        )}
                                        viewBox='0 0 20 20'
                                        aria-hidden='true'
                                    >
                                        <path d='M6 6L14 10L6 14V6Z' fill='currentColor' />
                                    </svg>
                                    {item.icon && (
                                        <item.icon
                                            className='mr-4 h-4 w-4 flex-shrink-0 text-icon'
                                            aria-hidden='true'
                                        />
                                    )}
                                    {item.displayName}
                                </Disclosure.Button>
                                <Disclosure.Panel className='space-y-1'>
                                    {item.children!.map((subItem: NavMenuItem) => (
                                        <NavLink
                                            key={subItem.displayName}
                                            to={subItem.path}
                                            className={classNames(
                                                subItem.current
                                                    ? 'bg-opacity-100 text-opacity-100'
                                                    : 'bg-opacity-0 text-opacity-80',
                                                'bg-inverse-200  hover:bg-opacity-50',
                                                'text-primary  hover:text-opacity-100',
                                                'group flex w-full items-center rounded-md py-2 pl-10 pr-2 font-medium ',
                                            )}
                                        >
                                            {subItem.icon && (
                                                <subItem.icon
                                                    className='mr-4 h-4 w-4 flex-shrink-0 text-icon'
                                                    aria-hidden='true'
                                                />
                                            )}
                                            {subItem.displayName}
                                        </NavLink>
                                    ))}
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                )
            })}
        </nav>
    )
}
export default Navigation
