/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import {authenticationContextFactory} from '@motiondesign/npm-common-utils/applicationhelpers'
import {UserInfo} from '@/constants/api'

export const {AuthProvider, useAuthState, setUserAuthenticated, setLoginSuccessRedirect} =
    authenticationContextFactory<UserInfo>('Squid-UI')
