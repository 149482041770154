/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */
import React from 'react'
import Navigation, {NavigationProps} from '@/components/Navigation'
import Logo from '@/components/Logo'
import classNames from 'classnames'

export type SidebarProps = {navigation: NavigationProps; className?: string}

const Sidebar = ({navigation, className}: SidebarProps) => {
    return (
        <div className={classNames(className, 'flex flex-grow flex-col md:overflow-y-auto md:pt-5')}>
            <button
                className='font-semibold text-primary hover:opacity-80 darkTheme:text-white hover:darkTheme:opacity-90'
                onClick={() => {
                    localStorage.removeItem('database')
                    window.location.reload()
                }}
            >
                <div className='flex flex-shrink-0 items-center space-x-3 px-4'>
                    <Logo />
                    <span>Squid Server</span>
                </div>
            </button>
            <div className='mt-5 flex flex-1 flex-col'>
                <Navigation {...navigation} />
            </div>
        </div>
    )
}

export default Sidebar
