/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import React, {PropsWithChildren} from 'react'
import ErrorPage from '../pages/error/Error'
import Horizontal from '../layouts/Horizontal'
import NotFound from '../pages/error/NotFound'
import {APP_PAGES, LOGIN_PAGES, NAVITEMS, PROFILE_ITEMS} from '@/constants/pages'
import Main from '../layouts/Main'
import {NonIndexRouteObject, RouteObject} from '@sentry/react/types/types'
import {ROLE} from '@/constants/role'

export interface IRoute extends NonIndexRouteObject {
    displayName: string
    icon?: string
    isNavItem?: boolean
    requiredRoles?: ROLE[]
}

function flattenRoutes(obj: any): IRoute[] {
    let result = []
    for (let key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null && obj[key].path !== undefined) {
            result.push(obj[key])
            result = result.concat(flattenRoutes(obj[key]))
        }
    }
    return result
}

const ProtectedRoute = ({route, children}: PropsWithChildren<{route: IRoute}>) => {
    // const [authContext] = useAuthState()

    // if (!authContext.userInfo || !userCanAccessRoute(authContext.userInfo, route.requiredRoles)) {
    //     return <Navigate to={PAGES.path} replace />
    // }

    return <React.Fragment>{children}</React.Fragment>
}

const appRoutes = flattenRoutes({APP_PAGES})
const profileRoutes = flattenRoutes({PROFILE_ITEMS})
const loginRoutes = flattenRoutes({LOGIN_PAGES})

/**
 * Wrap any routes that have user role requirements in ProtectedRoute.
 * Filter out any routes with no element.
 */
function protectRoutes(routes: IRoute[]): IRoute[] {
    return routes
        .filter((it) => it.element !== undefined)
        .map((route) => {
            if (route.requiredRoles) {
                route.element = <ProtectedRoute route={route}>{route.element}</ProtectedRoute>
            }
            return route
        })
}

const routes: RouteObject[] = [
    {
        element: <Main />,
        errorElement: <ErrorPage />,
        children: protectRoutes([...loginRoutes]),
    },
    {
        element: <Horizontal sidebar={{navigation: {items: NAVITEMS}}} profileDropdownItems={PROFILE_ITEMS} />,
        errorElement: <ErrorPage />,
        children: protectRoutes([...appRoutes, ...profileRoutes]),
    },
    {
        path: '*',
        element: <NotFound />,
    },
]

export default routes
