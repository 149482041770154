/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */
import React from 'react'
import classNames from 'classnames'

export interface LogoProps {
    className?: string
}

const Logo = ({className}: LogoProps) => {
    return (
        <div className={classNames(className, 'svg-inherit-color-fill flex h-8 w-8 items-center text-primary')}>
            <svg
                className='mx-auto'
                width='26'
                height='23'
                viewBox='0 0 26 23'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <g clipPath='url(#clip0_1_2)'>
                    <path d='M16.767 18.1502L8.34659 13.7412L0 18.1502L8.34659 23L16.767 18.1502Z' />
                    <path d='M26 12.7859L17.6534 8.81789L10.1193 12.7859L18.4659 17.1949L26 12.7859Z' />
                    <path d='M15.8807 7.71565L8.27273 4.11501L0.738636 7.71565L8.27273 11.6837L15.8807 7.71565Z' />
                    <path d='M24.375 3.30671L16.767 0L9.89773 3.30671L17.4318 6.90735L24.375 3.30671Z' />
                </g>
                <defs>
                    <clipPath id='clip0_1_2'>
                        <rect width='26' height='23' fill='white' />
                    </clipPath>
                </defs>
            </svg>
        </div>
    )
}

export default Logo
