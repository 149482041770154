/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */
import classNames from 'classnames'
import React from 'react'

const Card = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
    ({className, children, ...rest}, ref) => (
        <div {...rest} ref={ref} className={classNames(className, 'rounded-lg border border-gray-200 bg-white shadow')}>
            {children}
        </div>
    ),
)

const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
    ({className = 'flex items-center justify-between', children, ...rest}, ref) => (
        <div {...rest} ref={ref} className={classNames(className, 'border-b px-6 py-4')}>
            {children}
        </div>
    ),
)
const CardTitle = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
    ({className, children, ...rest}, ref) => (
        <div {...rest} ref={ref} className={classNames(className, 'text-base font-semibold leading-6 text-gray-900')}>
            {children}
        </div>
    ),
)

const CardBody = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
    ({className, children, ...rest}, ref) => (
        <div {...rest} ref={ref} className={classNames(className, 'p-6')}>
            {children}
        </div>
    ),
)

export {Card, CardBody, CardHeader, CardTitle}
